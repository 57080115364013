import React, { useEffect, useState } from "react";
import QRCode from "qrcode";
import {
  getAppsDetails,
} from "../../Services/APIs/appDetails";
import { dentalOfficeName } from "../../Utils/AppDetails/appDetails";

const QrCode = () => {
  const [qrCode, setQrcode] = useState("");

  const getInitialAppDetails = async () => {
    await getAppsDetails(dentalOfficeName)
      .then((resData) => {
        if (resData.data.data !== "No Data") {
          localStorage.setItem("appDetails", JSON.stringify(resData.data.data));
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    QRCode.toDataURL(window.location.href).then((data) => {
      setQrcode(data);
    });
    getInitialAppDetails();
  }, []);

  return (
    <div className="h-full w-full bg-indigo-400 text-white flex flex-col items-center pt-16 text-center">
      <p className=" font-bold" style={{ fontSize: "40px", fontFamily: "Inter", fontWeight: 700 }}>
        Switch to Mobile for a Better Experience
      </p>
      <p className=" text-2xl my-10 w-3/5" style={{ fontFamily: "Inter", fontWeight: 600 }}>
        To continue with your dental scan, please open this page on your
        mobile device or scan the QR code below with your phone's camera.
      </p>
      <div className="mt-8">
        {qrCode && (
          <img
            className=" h-72 mx-auto shadow-xl"
            src={qrCode}
            alt="Qr code"
          />
        )}
      </div>
    </div>
  )
};

export default QrCode;
