import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import ScToothSelector from "./scToothSelector"
import ScShoot from "./scShoot"


const ScFullScan = () => {
    const { dentalOfficeName } = useParams();
    const [isToothPicker, setToothPicker] = useState(true)
    const [activeImageSide, setImageSide] = useState(0)
    const { fullScanS3Folder } = useSelector((state) => state.fullScan)
    const { tpScanS3Folder } = useSelector((state) => state.tpScan)
    const navigate = useNavigate()
    
    useEffect(() => {
        if (!localStorage.getItem("usertpi")||JSON.parse(localStorage.getItem("isScanCompleted"))===true) {
            navigate(`/`)
        }
    }, [isToothPicker, activeImageSide])

    return (
        <div className="h-full">
            {
                isToothPicker ?
                    <ScToothSelector setImageSide={setImageSide} setToothPicker={setToothPicker} /> :
                    <ScShoot activeImageSide={activeImageSide} setToothPicker={setToothPicker} quickScan={false} />
            }
        </div>
    )
}

export default ScFullScan