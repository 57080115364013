
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { addImages } from "../../Store/fullScan"
import steps from "./steps";
import Scsteps from "./scSteps";
import crop from "../../Utils/crop"
import dataURItoBlob from "../../Utils/dataUriToBlob"
import { createScan, imageUpload, submitScan } from "../../Services/APIs/scan"
import ReshootPopup from "./reshootError"
import "../../App.css"
import { addQuickScan, clearQuickScan } from "../../Store/quickScan"
import SuccessScreen from "./submitSuccess"
import { fontStyle, fontWeight, companyBucket } from "../../Utils/tpscanStyle"
import teethSides from "../../Utils/teethSideNames"
import { postLogDetails } from "../../Services/APIs/appDetails"
const Preview = (props) => {
    const { image, setCamera, setToothPicker, activeImageSide, setDemo, quickScan, setImageSide } = props
    const dispatch = useDispatch()
    const { quickScanS3Folder } = useSelector((state) => state.quickScan)
    const { fullScanS3Folder } = useSelector((state) => state.fullScan)
    const [reshoot, setReshoot] = useState(false)
    const [errorType, setImageError] = useState('')
    const [isLoading, setLoading] = useState(false)
    const [isSuccess, setSuccess] = useState(false)


    const next = async () => {
        const rotate =JSON.parse(localStorage.getItem("is_self_check"))&& activeImageSide === 1
        const croppedImage = await crop(image, 3 / 2, rotate).then(canvas => {
            return canvas.toDataURL("image/jpeg", 0.75);
        })
        await upload(croppedImage)
    }

    const upload = async (dataUri) => {
        const blob = dataURItoBlob(dataUri)
        const file = new File([blob], "report_image.jpeg", { type: "image/jpeg" });
        const formData = new FormData();
        formData.append("report_image", file);;
        setLoading(true)
        setReshoot(false)
        const folderName = quickScan ? quickScanS3Folder : fullScanS3Folder
        const teethSide = `${teethSides[activeImageSide]} teeth`
        await imageUpload({ formData },teethSide).then((response) => {
            setLoading(false)

            const required = {
                title: JSON.parse(localStorage.getItem("is_self_check"))? Scsteps[activeImageSide].title:steps[activeImageSide].title,
                dataUri: URL.createObjectURL(blob),
                file: response.data.data?.imageName,
                isReshoot: response.data.data?.is_reshoot,
                mouth_detection_message: response.data.data?.mouth_detection_message
            }
            if (required.isReshoot) {
                console.log("54 came inside")
                setReshoot(true)
                if (response.data.data?.mouth_detection_message === "mouth far away") {
                    setImageError("mouth far away")
                } else if (response.data.data?.mouth_detection_message === "blur detected") {
                    setImageError("blur detected")
                }
                else {
                    setImageError("general")
                }
            } else {
                    dispatch(addImages(required))
                    if (localStorage.getItem("isAutomated") && activeImageSide < 4) {
                        setImageSide(activeImageSide + 1)
                        setDemo(true)
                    } else {
                        setToothPicker(true)
                    }

            }

        }).catch((error) => {
            console.log(error, "line no 75")
            setLoading(false)
            setReshoot(true)
            setImageError("failed")
        })


    }

  
    const createReport = async (report) => {
        await createScan(report).then((response) => {
            setSuccess(true)
            dispatch(clearQuickScan())

        }).catch((error) => {
            console.log(error,"line no 91")
            setReshoot(true)
            setImageError("failed")
        })
    }


    return (
        <>
            {
                isSuccess ? <SuccessScreen isFullScan={false} /> :

                    <div className="h-full">
                        <div className="relative top-0 h-full w-full">
                            <img src={image} alt="preview" className={`w-full h-full  ${ JSON.parse(localStorage.getItem("is_self_check")) && activeImageSide === 1 && "ransform rotate-180 "}`} />
                        </div>

                    
                                <div className="z-10 absolute bottom-5 w-full flex justify-evenly">
                                    <button
                                        className=" block w-40 h-12 bg-white text-black font-semibold rounded"
                                        onClick={() => { setCamera(true) }}>
                                        <span style={{ ...fontStyle, ...fontWeight }}> Rescan
                                        </span> </button>
                                    <button className=" block w-40  h-12 bg-white text-black font-semibold rounded" onClick={next} disabled={isLoading}

                                    >
                                        {
                                            isLoading ? <div className="loader-container">
                                                <div className="loader dark-loader"></div>
                                            </div> : <span style={{ ...fontStyle, ...fontWeight }}> Next</span>
                                        }
                                    </button>
                                </div>
                        

                        {reshoot && <ReshootPopup setReshoot={setReshoot} errorType={errorType} setCamera={setCamera} setDemo={setDemo} />}
                    </div>
            }
        </>
    )
}


export default Preview