import React,{useEffect} from "react";
import TpScanCarousel from "./tpScanCarousel";
import TpProgress from "./tpProgress";
import TpInfo from "./tpInfo";
import BookAppointment from "./bookAppointment";
import DownloadPdf from "./downloadPdf";
import { postBookAppointment } from "../../Services/APIs/annotation";
const BernFlow = () => {

useEffect(()=>{
  localStorage.setItem("isScanCompleted",true)
  postBookAppointment()
},[])

  return (
    <div>
      <BookAppointment />
      <TpScanCarousel />
      <div className="border pt-3 rounded to-gray-300 my-5  mx-6 pb-6">
        <TpInfo />
      </div>
      <DownloadPdf />
    </div>
  );
};

export default BernFlow;
