import React,{useEffect} from "react";
import close from "../../assets/Icons/close-white.png"
import steps from "./steps";
import {fontWeight,fontStyle,companyBucket,company_style_details,buttonStyle} from "../../Utils/tpscanStyle"
import teethSides from "../../Utils/teethSideNames";
import { postLogDetails } from "../../Services/APIs/appDetails";
const DemoScreens = (props) => {

    const { activeImageSide, setToothPicker, setDemo, setCamera, quickScan } = props

    const gotIt = () => {
        setDemo(false)
        setCamera(true)
    }
    useEffect(()=>{
        postLogDetails({
            uid: JSON.parse(localStorage.getItem("usertpi")).usertpi,
            payLoad: `viewed ${teethSides[activeImageSide]} teeth demo screen` 
        })
    },[])
    return (
        <div className="h-full w-full">
            <div className="z-20 relative h-full w-full text-end text-white ">
                <button className="p-1" onClick={() => { setToothPicker(true) }}> <img src={close} className="w-7 h-7 m-2" alt="close" /> </button>
                <h1 className=" text-center text-2xl font-semibold mt-5"style={{...fontWeight,...fontStyle}}>{steps[activeImageSide].title}</h1>
                <div className="w-full absolute bottom-8 text-center">
                    <ul className=" list-outside text-white text-start me-5 ms-14 mb-10 list-disc"style={{...fontWeight,...fontStyle}}>
                        {
                            steps[activeImageSide].tips.map((tip, i) => <li key={i}> {tip} </li>)
                        }
                    </ul>
                    <button className="text-center w-52 h-12 bg-white text-black font-semibold rounded" onClick={gotIt} >
                        <span style={{...fontStyle}}>Got It</span>  </button>
                </div>
            </div>
            <div className="z-10 absolute top-0 w-full h-full">
                <img src={steps[activeImageSide].image} alt={"front teeth"} className="w-full h-full" />
            </div>
        </div>
    )
}

export default DemoScreens